






























import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";

export default defineComponent({
  props: {
    type: {
      type: String,
      required: false,
      default: "list",
    },
    search: {
      type: String,
      required: false,
      default: "",
    },
  },

  setup(props, { root }) {
    const state = reactive({
      headers: [
        { value: "name", text: "Nazwa grupy" },
        { text: "", value: "actions", sortable: false, align: "right" },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      search: "",
      searchTimeout: undefined as any,
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const { page, itemsPerPage } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get("user/auth/group", {
          params: { page, itemsPerPage, search: state.search || undefined },
        })
        .then(({ data: { groups, total } }) => {
          state.empty = false;
          state.items = groups.map((group: any, index: number) => ({
            id: index,
            name: "",
            ...group,
          }));
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    const deleteItem = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`user/auth/group/${item.id}`)
        .then(() => {
          item.deleteDialog = false;
          fetchData();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    watch(
      () => props.search,
      (newSearch) => {
        state.search = newSearch;
        clearTimeout(state.searchTimeout);
        state.searchTimeout = setTimeout(fetchData, 500);
      }
    );

    onMounted(fetchData);
    watch(() => state.options, fetchData, { deep: true });

    return { state, fetchData, deleteItem };
  },
});
